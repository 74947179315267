import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useAppContext } from 'core/app'
import { VisitedSites } from 'modules/dashboard/components/visited-sites'
import { getGreeting } from 'modules/dashboard/utils'

export const DashboardPage = () => {
  const activeUser = useAppContext()
  const greeting = getGreeting()

  return (
    <Box px={5} py={3}>
      <Box>
        <Typography variant="h5">{`${greeting}, ${activeUser?.user.firstName}`}</Typography>
        <Typography variant="body2" color="textSecondary">
          Quick access your recent visited sites and actions
        </Typography>
      </Box>
      <Box mt={2} />
      <Typography variant="subtitle2">Recent sites</Typography>
      <Box mt={1} />
      <VisitedSites />
    </Box>
  )
}
