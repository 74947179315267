import React, { FC } from 'react'
import { Skeleton } from '@material-ui/lab'
import { Box, Grid, Link, makeStyles, Paper, Typography } from '@material-ui/core'
import { Language } from '@material-ui/icons'
import { useGetLastVisitedSites } from 'modules/sites/hooks/use-get-last-visited-sites'
import { LastVisitedSite } from 'modules/sites/types'
import { defaultThemeColors } from 'core/themes/default/default'

export const VisitedSites = () => {
  const { data, isLoading, error } = useGetLastVisitedSites()

  if (isLoading) {
    return (
      <Grid container spacing={2}>
        {Array(4)
          .fill(null)
          .map((_, index) => (
            <Grid item xs={6} lg={3} key={index}>
              <LoadingSkeleton />
            </Grid>
          ))}
      </Grid>
    )
  }

  if (error || !data) {
    return <div>Error</div>
  }

  return (
    <Grid container spacing={2}>
      {data.map((site) => (
        <Grid item xs={6} lg={3} key={site['@id']}>
          <VisitedSiteItem data={site} />
        </Grid>
      ))}
    </Grid>
  )
}

type VisitedSiteItemProps = {
  data: LastVisitedSite
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    textDecoration: 'none',
    border: '1px solid transparent',
    transition: 'border-color 0.3s ease',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}))

const VisitedSiteItem: FC<VisitedSiteItemProps> = ({ data }) => {
  const classes = useStyles()

  return (
    // @ts-ignore
    <Paper component="a" href={`/${data.siteData.id}`} className={classes.root}>
      <Box p={2} display="flex" style={{ gap: 16 }}>
        <Box
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          bgcolor={defaultThemeColors.purpleLight}
          borderRadius="borderRadius"
          width={48}
          height={48}
          flexShrink={0}
        >
          <Language color="primary" fontSize="medium" />
        </Box>
        <Box overflow="hidden" flexGrow={1}>
          <Typography noWrap>
            <strong>{data.siteData.name}</strong>
          </Typography>
          <Link color="textSecondary" noWrap>
            {data.siteData.url}
          </Link>
        </Box>
      </Box>
    </Paper>
  )
}

const LoadingSkeleton = () => {
  return (
    <Paper>
      <Box p={2} display="flex" style={{ gap: 16 }}>
        <Box
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          bgcolor={defaultThemeColors.purpleLight}
          borderRadius="borderRadius"
          width={48}
          height={48}
          flexShrink={0}
        >
          <Skeleton variant="circle" width={30} height={30} />
        </Box>
        <Box overflow="hidden" flexGrow={1}>
          <Skeleton variant="text" width="80%" height={20} />
          <Skeleton variant="text" width="60%" height={20} />
        </Box>
      </Box>
    </Paper>
  )
}
