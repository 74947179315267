import { httpService } from 'core/data'
import { HydraResponse } from 'core/types'
import { convertIdToIri, getIdFromIri } from 'core/utils'
import { LastVisitedSiteResponse, Site } from 'modules/sites/types'

export const sitesApi = {
  getSiteById: (siteId: number) => {
    return httpService.get<Site>(`/sites/${siteId}`).then((res) => res.data)
  },
  getSitesByIds: (siteIds: number[]) => {
    return httpService
      .get<HydraResponse<Site>>(`/sites`, { params: { pagination: false, id: siteIds } })
      .then((res) => res.data['hydra:member'])
  },
  setLastVisitedSite: (siteId: string) => {
    return httpService.post('/user_activities', {
      site: convertIdToIri(siteId, 'sites'),
    })
  },
  getLastVisitedSites: async () => {
    const data = await httpService
      .get<HydraResponse<LastVisitedSiteResponse>>('/user_activities')
      .then((res) => res.data['hydra:member'])

    const sites = await sitesApi.getSitesByIds(data.map((site) => +getIdFromIri(site.site)))

    return data.map((site) => {
      const siteData = sites.find((s) => s['@id'] === site.site)

      if (!siteData) {
        throw new Error('Site data not found')
      }

      return {
        ...site,
        siteData,
      }
    })
  },
}
