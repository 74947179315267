export type ResponseValidationError = {
  propertyPath: string
  message: string
}

interface NestedObject {
  [key: string]: NestedObject | string | string[]
}

function transformErrors(errors: { propertyPath: string; message: string }[]): NestedObject {
  const result: NestedObject = {}

  errors.forEach((error) => {
    const pathParts = error.propertyPath.split('.')

    let currentObject: NestedObject = result
    pathParts.forEach((pathPart, index) => {
      const isArray = /\[\d+\]/.test(pathPart)

      if (isArray) {
        const arrayName = pathPart.substring(0, pathPart.indexOf('['))
        const arrayIndex = parseInt(pathPart.match(/\d+/)![0], 10)

        currentObject[arrayName] = currentObject[arrayName] || []
        // @ts-ignore
        currentObject[arrayName][arrayIndex] = currentObject[arrayName][arrayIndex] || {}
        // @ts-ignore
        currentObject = currentObject[arrayName][arrayIndex] as NestedObject
      } else if (index === pathParts.length - 1) {
        if (currentObject[pathPart] === undefined) {
          currentObject[pathPart] = [error.message]
        } else if (Array.isArray(currentObject[pathPart])) {
          // @ts-ignore
          currentObject[pathPart].push(error.message)
        } else {
          // @ts-ignore
          currentObject[pathPart] = [currentObject[pathPart], error.message]
        }
      } else {
        currentObject[pathPart] = currentObject[pathPart] || {}
        currentObject = currentObject[pathPart] as NestedObject
      }
    })
  })

  return result
}

export const transformResponseValidationData = (
  errors: ResponseValidationError[] | null
): NestedObject => {
  const errorsObj: { [key: string]: string } = {}

  if (!errors) return errorsObj

  return transformErrors(errors)
}

export const getIdFromIri = (iri: string) => {
  try {
    const split = iri.split('/')
    return split[split.length - 1]
  } catch (e) {
    return iri
  }
}

export const convertIdToIri = (id: string | number, resource: string) => {
  return `/api/${resource}/${id}`
}

export const isIri = (str: string) => typeof str === 'string' && str.startsWith('/api')
export const parseIri = (str: string) => str.split('/').slice(2)
