import { useMutation } from 'react-query'
import { sitesApi } from 'modules/sites/services/sites-api'

export const useSetLastVisitedSite = () => {
  const { mutate, isLoading, error } = useMutation({
    mutationFn: (siteId: string) => sitesApi.setLastVisitedSite(siteId),
  })

  return { setLastVisitedSite: mutate, isLoading, error }
}
