import { useQuery } from 'react-query'
import { sitesApi } from 'modules/sites/services/sites-api'


export const useGetLastVisitedSites = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['last-visited-sites'],
    queryFn: () => sitesApi.getLastVisitedSites(),
  })

  return { data, isLoading, error }
}
